import { AxiosError } from 'axios'
import { Order, OrderItem, OrderScenario } from '../../../../services/api/order/OrderService.types'
import { UseOrderCreateProps, UserOrderCreateInternalRequest } from '../useOrder.types'
import {
    createOrderItem,
    refreshExistingOrderItem,
    refreshOrderItem,
    updateOrderItem
} from '../../../../services/api/order/OrderService'
import { useMemo } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import useAxios from '../../../useAxios'
import useToast from '../../../useToast'
import useTypedRouter from '../../../useTypedRouter'

export const CREATE_ORDER_ITEM_KEY = 'createOrderItem'

const useOrderItemCreate = ({ onSuccess, onError }: UseOrderCreateProps = {}) => {
    const {
        query: { edit }
    } = useTypedRouter<{ edit?: boolean }>()

    const axiosInstance = useAxios()
    const queryClient = useQueryClient()
    const toast = useToast()

    const scenario = useMemo<OrderScenario | undefined>(() => (edit ? 'edit' : undefined), [edit])

    return useMutation<OrderItem, AxiosError, UserOrderCreateInternalRequest>(
        [CREATE_ORDER_ITEM_KEY],
        async ({ onlyRefresh, data, orderId, orderItemId, initRefreshData = false }) => {
            //Update order item section
            if (orderItemId) {
                if (onlyRefresh) {
                    return refreshExistingOrderItem(axiosInstance, orderId, orderItemId, data, undefined, scenario)
                }
                return updateOrderItem(axiosInstance, orderId, orderItemId, data, undefined, scenario)
            }

            //Create order item section
            if (onlyRefresh) {
                return refreshOrderItem(axiosInstance, orderId, initRefreshData, data, undefined, scenario)
            }
            return createOrderItem(axiosInstance, orderId, data, undefined, scenario)
        },
        {
            onSuccess: (data, { orderId, orderItemId, onlyRefresh }) => {
                onSuccess?.(data)
                if (onlyRefresh) {
                    return
                }

                //Update order item
                if (orderItemId) {
                    queryClient.setQueriesData<Order>(['order', orderId], prev => {
                        return {
                            ...prev,
                            orderItems: prev.orderItems.map(item => {
                                return item.id === orderItemId ? data : item
                            })
                        }
                    })
                    return
                }

                //Create order item
                queryClient.setQueriesData<Order>(['order', orderId], prev => {
                    return {
                        ...prev,
                        orderItems: [...prev.orderItems, data]
                    }
                })
            },
            onError: error => {
                onError?.(error)
                const axiosError = error as AxiosError
                if (axiosError.response?.status === 400) {
                    const responseData = axiosError.response.data as { message: string }
                    toast.error(responseData.message)
                }
            }
        }
    )
}

export default useOrderItemCreate
